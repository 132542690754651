import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { Link } from 'gatsby';
import { FiInfo } from 'react-icons/fi';
import { Counter, LikeButton, RegVideo, Video } from '../components/Complete';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <section className="mdx-page">
      <h2>{`my first MDX file`}</h2>
      <div className="code">
        <pre><code parentName="pre" {...{
            "className": "language-js"
          }}>{`const IndexPage = 'john'
const IndexPage2 = 'john2'
`}</code></pre>
      </div>
      <div className="nice-text">
        <h3>{`Nice text`}</h3>
        <FiInfo className="nice-text-icon" mdxType="FiInfo" />
        <p>{`I'm baby put a bird on it sustainable messenger bag organic, narwhal slow-carb helvetica. Asymmetrical lo-fi celiac forage activated charcoal scenester jean shorts hell of. Irony gochujang lumbersexual vexillologist kickstarter distillery. Readymade vape helvetica direct trade pok pok hella lyft prism XOXO normcore.`}</p>
      </div>
      <h2>{`React Components`}</h2>
      <Counter mdxType="Counter" />
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`import React from 'react'
import styled from 'styled-components'
import { AiOutlineLike } from 'react-icons/ai'
const LikeButton = () => {
  const [value, setValue] = React.useState(0)
  return (
    <Wrapper>
      <div>
        <button onClick={() => setValue(value + 1)}>
          <AiOutlineLike />
        </button>
        <p>
          Liked the post?
          <br />
          Click thumbs up few times
        </p>
      </div>
      <span>+{value}</span>
    </Wrapper>
  )
}
`}</code></pre>
      <LikeButton mdxType="LikeButton" />
      <h2>{`Video component`}</h2>
      <h2>Video component</h2>
      <RegVideo mdxType="RegVideo" />
      <Video mdxType="Video" />
      <h2>regular heading</h2>
      <h2 title="true"> title heading</h2>
      <h4>inline code</h4>
      <p><inlineCode parentName="p">{`console.log('hello there');`}</inlineCode></p>
      <h4>Bloquote element</h4>
      <blockquote display="default">
  I'm baby put a bird on it sustainable messenger bag organic, narwhal slow-carb
  helvetica.
      </blockquote>
      <blockquote display="info">
  I'm baby put a bird on it sustainable messenger bag organic, narwhal slow-carb
  helvetica.
      </blockquote>
      <blockquote display="warning">
  I'm baby put a bird on it sustainable messenger bag organic, narwhal slow-carb
  helvetica.
      </blockquote>
      <blockquote>
  I'm baby put a bird on it sustainable messenger bag organic, narwhal slow-carb
  helvetica. Asymmetrical lo-fi celiac forage activated charcoal scenester jean
  shorts hell of. Irony gochujang lumbersexual vexillologist kickstarter
  distillery. Readymade vape helvetica direct trade pok pok hella lyft prism
  XOXO normcore.
      </blockquote>
      <h4>{`Javascript`}</h4>
      <pre><code parentName="pre" {...{
          "className": "language-js"
        }}>{`// comments
const name = 'john'
const channel = 'coding addict'

function featuredProducts(data) {
  return data.filter(item => {
    return item.featured === true
  })
}
featuredProducts()
`}</code></pre>
      <h4>{`JSX`}</h4>
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`import React from 'react'

const Counter = () => {
  const [count, setCount] = React.useState(0)
  return (
    <section className="counter">
      <h4>show some love to MDX</h4>
      <h4>likes {count}</h4>
      <button className="btn btn-danger" onClick={() => setCount(count + 1)}>
        i like mdx
      </button>
    </section>
  )
}
export default Counter
`}</code></pre>
      <h4>{`HTML`}</h4>
      <pre><code parentName="pre" {...{
          "className": "language-html"
        }}>{`<h1>hello world</h1>
<p class="random">random text</p>
<section class="contact">
  <div class="title">
    <h1>contact us</h1>
  </div>
</section>
`}</code></pre>
      <h4>{`CSS`}</h4>
      <pre><code parentName="pre" {...{
          "className": "language-css"
        }}>{`max-width: 500px;
border-radius: var(--radius);
padding: 1rem 1.5rem;
background: var(--clr-grey-10);
text-align: center;
`}</code></pre>
      <Link to="/posts" className="btn center-btn" mdxType="Link">
  All posts
      </Link>
    </section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      